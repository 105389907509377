import axios from 'axios';
export default {

    state: {
        generalZidenyExams: localStorage.getItem('generalZidenyExams') ? JSON.parse(localStorage.getItem('generalZidenyExams')) : null,
        studentZidenyExams: localStorage.getItem('studentZidenyExams') ? JSON.parse(localStorage.getItem('studentZidenyExams')) : null,
        questionsZidenyExams: localStorage.getItem('questionsZidenyExams') ? JSON.parse(localStorage.getItem('questionsZidenyExams')) : null,
        answeredQuestionsZidenyExams: localStorage.getItem('answeredQuestionsZidenyExams') ? JSON.parse(localStorage.getItem('answeredQuestionsZidenyExams')) : [],
        skippedQuestionsZidenyExams: localStorage.getItem('skippedQuestionsZidenyExams') ? JSON.parse(localStorage.getItem('skippedQuestionsZidenyExams')) : [],
        favoriteQuestionsZidenyExams: localStorage.getItem('favoriteQuestionsZidenyExams') ? JSON.parse(localStorage.getItem('favoriteQuestionsZidenyExams')) : [],
        resultsZidenyExams: localStorage.getItem('resultsZidenyExams') ? JSON.parse(localStorage.getItem('resultsZidenyExams')) : {},
        examDurationZidenyExams: localStorage.getItem('examDurationZidenyExams') ? JSON.parse(localStorage.getItem('examDurationZidenyExams')) : '',
    },
    getters: {
        getGeneralZidenyExams: state => state.generalZidenyExams,
        getStudentZidenyExams: state => state.studentZidenyExams,
        getQuestionsZidenyExams: state => state.questionsZidenyExams,
        getAnswerQuestionZidenyExams: state => state.answeredQuestionsZidenyExams,
        getskippedQuestionsZidenyExams: state => state.skippedQuestionsZidenyExams,
        getFavoriteQuestionsZidenyExams: state => state.favoriteQuestionsZidenyExams,
        getResultsZidenyExams: state => state.resultsZidenyExams,
        getExamDurationZidenyExams: state => state.examDurationZidenyExams,
    },
    mutations: {
        setGeneralZidenyExams(state, data) {
            state.generalZidenyExams = data;
        },
        setStudentZidenyExams(state, data) {
            state.studentZidenyExams = data;
        },
        setQuestionsZidenyExams(state, data) {
            state.questionsZidenyExams = data;
        },
        setAnsweredQuestionsZidenyExams(state, answeredQuestionsZidenyExams) {
            state.answeredQuestionsZidenyExams = answeredQuestionsZidenyExams;
            localStorage.setItem('answeredQuestionsZidenyExams', JSON.stringify(state.answeredQuestionsZidenyExams));
        },
        setSkippedQuestionsZidenyExams(state, skippedQuestionsZidenyExams) {
            state.skippedQuestionsZidenyExams = skippedQuestionsZidenyExams;
            localStorage.setItem('skippedQuestionsZidenyExams', JSON.stringify(state.skippedQuestionsZidenyExams));
        },
        setFavoriteQuestionsZidenyExams(state, favoriteQuestionsZidenyExams) {
            state.favoriteQuestionsZidenyExams = favoriteQuestionsZidenyExams;
            localStorage.setItem('favoriteQuestionsZidenyExams', JSON.stringify(state.favoriteQuestionsZidenyExams));
        },
        setResultsQuestionsZidenyExams(state, resultsZidenyExams) {
            state.resultsZidenyExams = resultsZidenyExams;
            localStorage.setItem('resultsZidenyExams', JSON.stringify(state.resultsZidenyExams));
        },
        setDurationZidenyExams(state, examDurationZidenyExams) {
            state.examDurationZidenyExams = examDurationZidenyExams;
            localStorage.setItem('examDurationZidenyExams', JSON.stringify(state.examDurationZidenyExams));
        },
        RESET_GENERAL_ZIDNEYEXAM_DATA(state) {
            state.generalZidenyExams = {};
        },
        RESET_STUDENT_ZIDNEYZIDNEYEXAM_DATA(state) {
            state.studentZidenyExams = {};
        },
        RESET_QUETIONS_ZIDNEYZIDNEYEXAM_DATA(state) {
            state.questionsZidenyExams = {};
        },
        RESET_ANSWERED_QUESTIONS_ZIDNEYEXAM_DATA(state) {
            state.answeredQuestionsZidenyExams = [];
        },
        RESET_SKIPPED_QUESTIONS_ZIDNEYEXAM_DATA(state) {
            state.skippedQuestionsZidenyExams = [];
        },
        RESET_FAVORITE_QUESTIONS_ZIDNEYEXAM_DATA(state) {
            state.favoriteQuestionsZidenyExams = [];
        },
        RESET_RESULTS_ZIDNEYEXAM_DATA(state) {
            state.resultsZidenyExams = {};
        },
        RESET_ExamDuration_ZIDNEYEXAM_DATA(state) {
            state.examDurationZidenyExams = {};
        }
    },
    actions: {
        updateGeneralZidenyExams({ commit }, data) {
            commit('setGeneralZidenyExams', data);
        },
        updateStudentZidenyExams({ commit }, data) {
            commit('setStudentZidenyExams', data);
        },
        updateQuestionsZidenyExams({ commit }, data) {
            commit('setQuestionsZidenyExams', data);
        },
        updateAnsweredQuestionsZidenyExams({ commit }, data) {
            commit('setAnsweredQuestionsZidenyExams', data);
        },
        updateSkippedQuestionsZidenyExams({ commit }, data) {
            commit('setSkippedQuestionsZidenyExams', data);
        },
        updateFavoriteQuestionsZidenyExams({ commit }, data) {
            commit('setFavoriteQuestionsZidenyExams', data);
        },
        updateResultsQuestionsZidenyExams({ commit }, data) {
            commit('setResultsQuestionsZidenyExams', data);
        },
        updateDurationZidenyExams({ commit }, data) {
            commit('setDurationZidenyExams', data);
        },
        clearZidenyExams({ commit }) {
            commit('RESET_ANSWERED_QUESTIONS_ZIDNEYEXAM_DATA');
            commit('RESET_STUDENT_ZIDNEYZIDNEYEXAM_DATA');
            commit('RESET_QUETIONS_ZIDNEYZIDNEYEXAM_DATA');
            commit('RESET_GENERAL_ZIDNEYEXAM_DATA');
            commit('RESET_SKIPPED_QUESTIONS_ZIDNEYEXAM_DATA');
            commit('RESET_FAVORITE_QUESTIONS_ZIDNEYEXAM_DATA');
            commit('RESET_RESULTS_ZIDNEYEXAM_DATA');
            commit('RESET_ExamDuration_ZIDNEYEXAM_DATA');
        },
        async submitZidenyExams({ state, commit }) {
            const zidney_student_exam_id = state.studentZidenyExams.id;
            const answers = state.answeredQuestionsZidenyExams;
            try {
                const response = await axios.post('zidney-exam/result', { zidney_student_exam_id, answers });

                const { topic, zidney_exam, answers_exam } = response.data.data;
                const resultsZidenyExams = {
                    topic,
                    zidney_exam,
                    answers_exam

                };

                commit('setResultsQuestionsZidenyExams', resultsZidenyExams);

            } catch (error) {

                console.error(error);
            }
        },
        async submitSelfZidenyExam({ state, commit }) {
            const self_student_exam_id = state.studentZidenyExams.id;
            const answers = state.answeredQuestionsZidenyExams;
            
            try {
                const response = await axios.post('self-student-exams/result', { self_student_exam_id, answers });

                const { self_student_exam } = response.data.data;
                const resultsZidenyExams = {
                    self_student_exam,
                };

                commit('setResultsQuestionsZidenyExams', resultsZidenyExams);

            } catch (error) {

                console.error(error);
            }
        }
    }
}
