


export default {

    state: {
        answeredQuestions: localStorage.getItem('answeredQuestions') ? JSON.parse(localStorage.getItem('answeredQuestions')):[]
        
    },
    getters: {
        getDefaultAnswerQuestion: state => state.answeredQuestions,

    },
    mutations: {
        setAnsweredQuestions(state, answeredQuestions) {
            state.answeredQuestions = answeredQuestions;
            localStorage.setItem('answeredQuestions', JSON.stringify(state.answeredQuestions));
        },
    },
    actions: {
        setDefaultAnswersQuestions({ commit }, data) {
            let answersQuestionsArray = []
            data.forEach(element => {
                answersQuestionsArray.push({"question_id":element.id,"answers":[]})
                
            });
            commit('setAnsweredQuestions', answersQuestionsArray);
        },

       
    }
}
