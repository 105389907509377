import axios from 'axios';
export default {

    state: {
        pathMCQExams: localStorage.getItem('pathMCQExams') ? JSON.parse(localStorage.getItem('pathMCQExams')) : null,
        studentPathMCQExams: localStorage.getItem('studentPathMCQExams') ? JSON.parse(localStorage.getItem('studentPathMCQExams')) : null,
        questionsPathMCQExams: localStorage.getItem('questionsPathMCQExams') ? JSON.parse(localStorage.getItem('questionsPathMCQExams')) : null,
        answeredQuestionsPathMCQExams: localStorage.getItem('answeredQuestionsPathMCQExams') ? JSON.parse(localStorage.getItem('answeredQuestionsPathMCQExams')) : [],
        skippedQuestionsPathMCQExams: localStorage.getItem('skippedQuestionsPathMCQExams') ? JSON.parse(localStorage.getItem('skippedQuestionsPathMCQExams')) : [],
        favoriteQuestionsPathMCQExams: localStorage.getItem('favoriteQuestionsPathMCQExams') ? JSON.parse(localStorage.getItem('favoriteQuestionsPathMCQExams')) : [],
        resultsPathMCQExams: localStorage.getItem('resultsPathMCQExams') ? JSON.parse(localStorage.getItem('resultsPathMCQExams')) : {},
        examDurationPathMCQExams: localStorage.getItem('examDurationPathMCQExams') ? JSON.parse(localStorage.getItem('examDurationPathMCQExams')) : '',
    },
    getters: {
        getPathMCQExams: state => state.pathMCQExams,
        getStudentPathMCQExams: state => state.studentPathMCQExams,
        getQuestionsPathMCQExams: state => state.questionsPathMCQExams,
        getAnswerQuestionPathMCQExams: state => state.answeredQuestionsPathMCQExams,
        getskippedQuestionsPathMCQExams: state => state.skippedQuestionsPathMCQExams,
        getFavoriteQuestionsPathMCQExams: state => state.favoriteQuestionsPathMCQExams,
        getResultsPathMCQExams: state => state.resultsPathMCQExams,
        getExamDurationPathMCQExams: state => state.examDurationPathMCQExams,
    },
    mutations: {
        setPathMCQExams(state, data) {
            state.pathMCQExams = data;
        },
        setStudentPathMCQExams(state, data) {
            state.studentPathMCQExams = data;
        },
        setQuestionsPathMCQExams(state, data) {
            state.questionsPathMCQExams = data;
        },
        setAnsweredQuestionsPathMCQExams(state, answeredQuestionsPathMCQExams) {
            state.answeredQuestionsPathMCQExams = answeredQuestionsPathMCQExams;
            localStorage.setItem('answeredQuestionsPathMCQExams', JSON.stringify(state.answeredQuestionsPathMCQExams));
        },
        setSkippedQuestionsPathMCQExams(state, skippedQuestionsPathMCQExams) {
            state.skippedQuestionsPathMCQExams = skippedQuestionsPathMCQExams;
            localStorage.setItem('skippedQuestionsPathMCQExams', JSON.stringify(state.skippedQuestionsPathMCQExams));
        },
        setFavoriteQuestionsPathMCQExams(state, favoriteQuestionsPathMCQExams) {
            state.favoriteQuestionsPathMCQExams = favoriteQuestionsPathMCQExams;
            localStorage.setItem('favoriteQuestionsPathMCQExams', JSON.stringify(state.favoriteQuestionsPathMCQExams));
        },
        setResultsQuestionsPathMCQExams(state, resultsPathMCQExams) {
            state.resultsPathMCQExams = resultsPathMCQExams;
            localStorage.setItem('resultsPathMCQExams', JSON.stringify(state.resultsPathMCQExams));
        },
        setDurationPathMCQExams(state, examDurationPathMCQExams) {
            state.examDurationPathMCQExams = examDurationPathMCQExams;
            localStorage.setItem('examDurationPathMCQExams', JSON.stringify(state.examDurationPathMCQExams));
        },
        RESET_GENERAL_PATHMCQEXAM_DATA(state) {
            state.pathMCQExams = {};
        },
        RESET_STUDENT_PATHMCQEXAM_DATA(state) {
            state.studentPathMCQExams = {};
        },
        RESET_QUETIONS_PATHMCQEXAM_DATA(state) {
            state.questionsPathMCQExams = {};
        },
        RESET_ANSWERED_QUESTIONS_PATHMCQEXAM_DATA(state) {
            state.answeredQuestionsPathMCQExams = [];
        },
        RESET_SKIPPED_QUESTIONS_PATHMCQEXAM_DATA(state) {
            state.skippedQuestionsPathMCQExams = [];
        },
        RESET_FAVORITE_QUESTIONS_PATHMCQEXAM_DATA(state) {
            state.favoriteQuestionsPathMCQExams = [];
        },
        RESET_RESULTS_PATHMCQEXAM_DATA(state) {
            state.resultsPathMCQExams = {};
        },
        RESET_ExamDuration_PATHMCQEXAM_DATA(state) {
            state.examDurationPathMCQExams = {};
        }
    },
    actions: {
        updatePathMCQExams({ commit }, data) {
            commit('setPathMCQExams', data);
        },
        updateStudentPathMCQExams({ commit }, data) {
            commit('setStudentPathMCQExams', data);
        },
        updateQuestionsPathMCQExams({ commit }, data) {
            commit('setQuestionsPathMCQExams', data);
        },
        updateAnsweredQuestionsPathMCQExams({ commit }, data) {
            commit('setAnsweredQuestionsPathMCQExams', data);
        },
        updateSkippedQuestionsPathMCQExams({ commit }, data) {
            commit('setSkippedQuestionsPathMCQExams', data);
        },
        updateFavoriteQuestionsPathMCQExams({ commit }, data) {
            commit('setFavoriteQuestionsPathMCQExams', data);
        },
        updateResultsQuestionsPathMCQExams({ commit }, data) {
            commit('setResultsQuestionsPathMCQExams', data);
        },
        updateDurationPathMCQExams({ commit }, data) {
            commit('setDurationPathMCQExams', data);
        },
        clearPathMCQExams({ commit }) {
            commit('RESET_ANSWERED_QUESTIONS_PATHMCQEXAM_DATA');
            commit('RESET_STUDENT_PATHMCQEXAM_DATA');
            commit('RESET_QUETIONS_PATHMCQEXAM_DATA');
            commit('RESET_GENERAL_PATHMCQEXAM_DATA');
            commit('RESET_SKIPPED_QUESTIONS_PATHMCQEXAM_DATA');
            commit('RESET_FAVORITE_QUESTIONS_PATHMCQEXAM_DATA');
            commit('RESET_RESULTS_PATHMCQEXAM_DATA');
            commit('RESET_ExamDuration_PATHMCQEXAM_DATA');
        },
        async submitPathMCQExams({ state, commit }) {
            const student_lesson_subject_track_id = 1;
            const student_subject_track_id = 1;
            const student_track_id = 3;
            const student_exam_id = state.studentPathMCQExams.id;
            const answers = state.answeredQuestionsPathMCQExams;
            try {
                const response = await axios.post('student-mcq-track-exam/result', { 
                    student_subject_track_id,
                    student_lesson_subject_track_id,
                    student_track_id,
                    student_exam_id,
                     answers });

                const { exam, student_exam, messages } = response.data.data;
                const resultsPathMCQExams = {
                    exam,
                    student_exam,
                    messages

                };

                commit('setResultsQuestionsPathMCQExams', resultsPathMCQExams);

            } catch (error) {

                console.error(error);
            }
        },
        async submitPathMCQExams({ state, commit }) {
            const self_student_exam_id = state.studentPathMCQExams.id;
            const answers = state.answeredQuestionsPathMCQExams;
            
            try {
                const response = await axios.post('self-student-exams/result', { self_student_exam_id, answers });

                const { self_student_exam } = response.data.data;
                const resultsPathMCQExams = {
                    self_student_exam,
                };

                commit('setResultsQuestionsPathMCQExams', resultsPathMCQExams);

            } catch (error) {

                console.error(error);
            }
        }
    }
}
