import axios from "axios";
import i18n from "@/i18n"

export default {
    namespaced: true,
    state: {
        token : null,
        user : null,
        role: '',
        loginFailure: false,
        buttonStatus : false,
        expires_in:'',
        currentLanguage:'',
        permissions:[]

    },
    getters: {
        authenticated (state) {
            return state.token != null && state.user != null;
        },
        user (state) {
            return state.user;
        },
        permissions (state) {
            return state.permissions;
        },
        loginFailure(state) {
            return state.loginFailure;
        },
        buttonStatus(state) {
            return state.buttonStatus;
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_PERMISSIONS(state,permissions) {
            state.permissions = permissions;
        },
        SET_EXPIRES_IN(state, expires_in) {
            state.expires_in = expires_in;
        },
        loginFailureData(state, loginFailure) {
            state.loginFailure = loginFailure;
        },
        changeButtonStatus(state, isFinished) {
            state.buttonStatus = isFinished;
        },
        clearAuthData(state) {
            state.token = null;
            state.user = null;
        },
        currentLanguage(state,currentLanguage) {
            state.currentLanguage = currentLanguage;
        },
        currentDirection(state,currentDirection) {
            state.currentDirection = currentDirection;
        }
    },
    actions: {
      async  login({commit,dispatch}, authData) {
          commit('changeButtonStatus', true)

          let response =   await axios.post('auth/login',
              {
                  mobile_or_email: authData.mobile_or_email,
                  password: authData.password
              }
          );
      let code = response.data.code;
         if(code == 200) {
              return  dispatch('attempt',response.data.data.access_token);
         }else  {
             commit('loginFailureData', true);
             commit('changeButtonStatus', false)
         }
        },
      async attempt({commit,state,dispatch},token){
          if(token) {
              commit('SET_TOKEN',token);
          }
          if(!state.token) {
              return
          }
          return  dispatch('profile');
        },
        async logout({commit}){
         return  axios.get('auth/logout').then(() => {
                 commit('clearAuthData');
                 window.location.reload();
             }
         )
      },
      async profile({commit,dispatch}){
            let response = await axios.get('auth/profile');
            if(response.data.code == 200 ) {
                commit('SET_USER',response.data.data.user);
                commit('changeButtonStatus', false);
                dispatch('setLanguage',response.data.data.user.def_lang);
                commit('SET_PERMISSIONS',response.data.data.permissions);
                dispatch('setDirection', {'rtl' : response.data.data.user.language.rtl, 'lang' :response.data.data.user.def_lang });

            } else {
                commit('clearAuthData');
            }
        },
        setDirection({commit}, lang) {
            let direction = 'ltr';
            if (lang.rtl == 1) {
                direction = 'rtl';
            }
            commit('currentDirection',direction);
            localStorage.setItem('directionTheme', direction);
            document.getElementsByTagName("html")[0].setAttribute('dir', direction);
            document.getElementsByTagName("html")[0].setAttribute('lang', lang.lang);
            
        },
        setLanguage({commit},def_lang) {
            commit('currentLanguage',def_lang);
            i18n.global.locale.value =def_lang;
        },
        refreshToken({dispatch}) {
            let response =  axios.get('auth/refresh');
            if (response.data.code == 200) {
                return  dispatch('attempt',response.data.data.access_token);
            }
        }
    }
}
