import axios from "axios";

export default {
    namespaced: true,
    state: {
        languages : [],
        divisions:[],
        categories : [],
        groups:[],
        departments:[],
        baskets:[],
        subjects:[],
        tags:[],
        charts:[],
        isSubjectChanged: false
    },
    getters: {
        isSubjectChanged (state) {
           return state.isSubjectChanged;
          },
        chartsEdit: (state) => (id) => {
            return state.charts.filter(c => c.id !== id)
        },
        categoriesEdit: (state) => (id) => {
            return state.categories.filter(c => c.id !== id)
        },
        languagesTabs (state) {
          return   state.languages.map(d => ({
                languageName: d.languageName,
                language_universal: d.language_universal,
                name: "",
                id: d.id,

            }))
        },
    },
    mutations: {
        SET_LANGUAGES(state, languages) {
            state.languages = languages;
        },
        SET_DIVISIONS(state, divisions) {
            state.divisions = divisions;
        },
        SET_GROUPS(state, groups) {
            state.groups = groups;
        },
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        SET_DEPARTMENTS(state, departments) {
            state.departments = departments;
        },
        SET_SUBJECTS(state, subjects) {
            state.subjects = subjects;
        },
        SET_BASKETS(state, baskets) {
            state.baskets = baskets;
        },
        SET_TAGS(state, tags) {
            state.tags = tags;
        },
        SET_CHARTS(state, charts) {
            state.charts = charts;
        },
        SET_IS_SUBJECT_CHANGED(state, bool) {
            state.isSubjectChanged = bool;
        },

    },
    actions: {
   async languages({commit}){
          let response = await  axios.get('list/languages');
          if (response.data.code == 200) {
              commit('SET_LANGUAGES',response.data.data);
          }
      },

        async divisions({commit},status){
            let response = await  axios.get('divisions/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_DIVISIONS',response.data.data);

            }
        },

        async categories({commit},status){
            let response = await  axios.get('categories/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_CATEGORIES',response.data.data);
            }
        },

        async groups({commit},status){
            let response = await  axios.get('groups/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_GROUPS',response.data.data);
            }
        },
        async departments({commit},status){
            let response = await  axios.get('departments/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_DEPARTMENTS',response.data.data);
            }
        },
        async subjects({commit},status){
            let response = await  axios.get('subjects/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_SUBJECTS',response.data.data);
            }
        },

        async baskets({commit},status){
            let response = await  axios.get('baskets/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_BASKETS',response.data.data);
            }
        },

        async tags({commit},status){
            let response = await  axios.get('tags/get_tags');
            if (response.data.code == 200) {
                commit('SET_TAGS',response.data.data);
            }
        },
        async charts({commit},status){
            let response = await  axios.get('charts/get_by_status?status='+status);
            if (response.data.code == 200) {
                commit('SET_CHARTS',response.data.data);
         }
        },

        changeSubject({commit}, bool){
            commit('SET_IS_SUBJECT_CHANGED', bool);
        }
    }
}
